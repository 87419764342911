import React, { useState, useEffect } from 'react';
import Pipeline from './Pipeline.js';
import Tests from './Tests.js';
import Monitoring from './Monitoring.js';
import Login from './Login.js';
import SupplyPages from './SupplyPages.js';
import SupplyInfo from './SupplyInfo.js';
import DemandPages from './DemandPages.js';
import GMPages from './GMPages.js';
import Expenses from './Expenses/index.js';
import EmailTemplate from './EmailTemplate.js';
import Portfolio from './Portfolio.js';
import Signature from './Signature.js';
import Assistant from './Assistant.js'
import Admin from './Admin.js';
import C from '../config.js';
import { AuthStore } from '../store.js';
import { get } from '../fetch.js'

// https://icons.getbootstrap.com/

const sectionList = [
    {
        icon: 'bi bi-view-list',
        title: 'Tools',
        main: true,
        render: (user) => <Portfolio />
    },
    {
        icon: 'bi bi-graph-up-arrow',
        title: 'Supply Pages',
        main: true,
        cache: true,
        render: (user) => <SupplyPages user={user} />
    },
    {
        icon: 'bi bi-info-circle',
        title: 'Supply Info',
        main: true,
        admin: true,
        cache: true,
        render: (user) => <SupplyInfo user={user} />
    },
    {
        icon: 'bi bi-clipboard-data',
        title: 'Demand Pages',
        main: true,
        cache: true,
        render: (user) => <DemandPages user={user} />
    },
    {
        icon: 'bi bi-cash-coin',
        title: 'GM Pages',
        main: true,
        cache: true,
        render: (user) => <GMPages user={user} />
    },
    {
        icon: 'bi bi-file-earmark-medical',
        title: 'Expense Report (Beta)',
        main: true,
        admin: true,
        render: (user) => <Expenses />
    },
    {
        icon: 'bi bi-envelope-heart',
        title: 'Signature',
        main: true,
        render: (user) => <Signature />
    },
    {
        icon: 'bi bi-robot',
        title: 'Gravvy (Ai)',
        main: true,
        mode: 'dark',
        cache: true,
        render: (user) => <Assistant />
    },
    {
        icon: 'bi bi-envelope-paper-fill',
        title: 'Email template',
        admin: true,
        main: true,
        render: (user) => <EmailTemplate user={user} />,
    },
    {
        icon: 'bi bi-key-fill',
        title: 'Admin',
        admin: true,
        main: true,
        render: (user) => <Admin user={user} />,
    },
    {
        icon: 'bi bi-boxes',
        title: 'Pipeline & Build',
        admin: true,
        main: true,
        render: (user) => <Pipeline user={user} />,
    },
    {
        icon: 'bi bi-diagram-2-fill',
        title: 'Test',
        admin: true,
        main: true,
        render: (user) => <Tests user={user} />
    },
    {
        icon: 'bi bi-display',
        title: 'Monitoring',
        admin: true,
        main: true,
        render: (user) => <Monitoring user={user} />
    },
    {
        icon: 'bi bi-hdd-network',
        title: 'Swagger',
        admin: true,
        collection: (sectionList) => sectionList.filter(s => s.swagger),
        main: true,
    },
    {
        icon: 'bi bi-link',
        title: 'Links',
        admin: true,
        collection: (sectionList) => sectionList.filter(s => s.link),
        main: true,
    },
    {
        icon: 'bi bi-pie-chart-fill',
        title: 'MAYO Plot',
        admin: true,
        swagger: true,
        render: C.projects.mayoPlot.dev,
        auth: true,
    },
    {
        icon: 'bi bi-bar-chart-steps',
        title: 'Task Scheduler',
        admin: true,
        swagger: true,
        render: C.projects.taskScheduler.prod,
        auth: true,
    },
    {
        icon: 'bi bi-envelope-check',
        title: 'Notification Service',
        admin: true,
        swagger: true,
        render: C.projects.notificationService.dev,
        auth: true,
    },
    {
        icon: 'bi bi-file-earmark-binary',
        title: 'Docker Logger',
        admin: true,
        link: C.projects.logger
    },
    {
        icon: 'bi bi-bar-chart-steps',
        title: 'Analytics',
        admin: true,
        link: C.projects.analytics
    },
    {
        icon: 'bi bi-database-up',
        title: 'DB Manager',
        admin: true,
        link: C.projects.db
    },
    {
        icon: 'bi bi-view-stacked',
        title: 'Portainer',
        admin: true,
        link: C.projects.portainer.dev
    },
    {
        icon: 'bi bi-microsoft-teams',
        title: 'Core Overview',
        admin: true,
        link: C.projects.coreOverview
    }
]

const Render = ({ section, user }) => {
    const [delay, setDelay] = useState(section.cache ? true : false)
    const res = (<div id="sectionWrapper" className={`col-sm p-3 ${section.mode ? 'darkMode' : ''}`}>
        {(typeof section.render === 'function') ? section.render(user) : <Frame auth={section.auth} url={section.render} />}
    </div>);

    async function checkCache() {
        const res = await get('reports/cacheReady');
        if (res.loaded) {
            setDelay(false)
        }
        else {
            setTimeout(() => checkCache(), 2000);
        }
    }

    useEffect(() => {
        if (section.cache) {
            checkCache()
        }
    }, [])

    if (section.cache) {
        if (delay) return <p>Loading...</p>
        return res

    } else {
        return res
    }
}

const Frame = (props) => {
    const [loadingIframe, setLoadingIframe] = useState(true);
    const [env, setEnv] = useState(null);
    const [token, setToken] = useState(true);
    const [target, setTarget] = useState(props.url);

    const envChecker = (val) => {
        setEnv(val);
        if (target.includes('scheduler') && val !== 'test') {
            setTarget(C.projects.taskScheduler.prod)
        }
    };

    useEffect(() => {
        setEnv(null);
        setTarget(props.url);
    }, [props.url]);

    return (
        <>
            {props.auth && C.projects && (<div style={{ padding: "1%" }}>
                <select className="form-select small" onChange={(e) => envChecker(e.target.value)} aria-label="Default select example">
                    <option value={''} >-- Select --</option>
                    {Object.keys(C.projects.dashboard).map((item) => <option key={item} value={C.projects.dashboard[item].backend}>{item}</option>)}
                </select>
                <Login url={env + '/auth'} onLogin={(usr) => setToken(usr.token)} />
                {token && <pre className="elips" style={{ maxWidth: '800px', margin: "0 2%", display: 'inline-block' }}>
                    {token}
                </pre>}
            </div>
            )}
            <iframe
                style={{
                    width: "100%",
                    height: "100%",
                }}
                src={target}
                onLoad={() => setLoadingIframe(false)}
            ></iframe>
            {loadingIframe ? <p> Loading iframe</p> : null}
        </>
    );
}

const App = (props) => {
    const { user } = props;
    const { isSudo } = user;
    const [section, setSection] = useState();

    const selectedList = getSelectedList();

    function getSelectedList() {
        if (isSudo) return sectionList.filter(s => s.main)
        else return sectionList.filter(s => !s.admin && s.main);
    }

    function displaySection() {
        if (section.link) {
            window.open(section.link, '_blank');
            setSection(null);
        }
        return <Render section={section} user={user} />
    }

    const Grid = (props) => {
        return (
            <div className="container col-sm p-3 min-vh-100">
                <div className="row">
                    {props.list.map((item, index) => (
                        <div key={index} className="col-md-4 col-sm-6 mb-4 gridItem" style={{ cursor: 'pointer' }}>
                            <div className="card">
                                <div className="card-body" onClick={() => setSection(item)}>
                                    <i className={item.icon + ' fs-1'} />
                                    <h5 className="card-title">{item.title}</h5>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        // App
        <div className="container-fluid">
            <div className="row flex-nowrap">
                {/* vertical Menu */}
                <div className="col-sm-auto bg-light sticky-top menu">
                    <div className="d-flex flex-sm-column flex-row flex-nowrap bg-light sticky-top">
                        <ul className="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mb-auto mx-auto justify-content-between w-100">
                            {selectedList.map(sec => (<li key={sec.icon} onClick={() => setSection(sec)}>
                                <a href={"#"} className={`nav-link ${section && (section.title === sec.title) ? 'selected' : ''}`} style={{ borderRadius: '0', borderBottom: '1px solid white', color: 'rgba(0,0,0,0.65)' }} title={sec.title} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
                                    <p>{sec.title}</p>
                                </a>
                            </li>))}
                        </ul>
                    </div>
                </div>
                {/* Main View */}
                {!section && <Grid list={selectedList} />}
                {section && section.collection && <Grid list={section.collection(sectionList)} />}
                {section && !section.collection && displaySection()}
            </div>
        </div>
    )
}

const Wrapper = () => {
    const user = AuthStore.user('google');
    return (user ? <App user={user} /> : null);
}

export default Wrapper
