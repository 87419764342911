import React, { useState, useEffect } from 'react';

const ModalWrapper = (props) => {
    const { buttonTitle, title, children } = props;
    const [show, setShow] = useState(props.open || false);

    const style = props.style || 'primary';

    useEffect(() => {
        if (!show && props.onClose) props.onClose(); 
    }, [show])

    return (
        <>
            {!show && <button type="button" className={`btn btn-${style}`} data-mdb-modal-init data-mdb-target="#exampleModal" onClick={() => setShow(true)}>
                {buttonTitle}
            </button>}

            <div className="modal top fade show" style={{ display: show ? 'block' : 'none', background: show ? '#000000c2' : 'none' }} id="exampleModal" tabIndex="-1" data-mdb-backdrop="true" data-mdb-keyboard="true">
                <div className="modal-dialog modal-xl" style={{maxWidth: props.large ? '80%' : 'min-content'}}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                            <button type="button" className="btn-close" data-mdb-dismiss="modal" aria-label="Close" onClick={() => setShow(false)} ></button>
                        </div>
                        <div className="modal-body" style={{color: 'black'}}>
                            <div className="card-body p-5 text-center">

                                <form className="mb-md-5 mt-md-4 pb-5">
                                    {children}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ModalWrapper