import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createRoot } from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './index.css';
import C from './config.js'
import Main from "./containers/index.js";
import DemandPages from './containers/DemandPages.js';
import Expenses from './containers/Expenses/index.js';
import Portfolio from './containers/Portfolio.js';
import Signature from './containers/Signature.js';
import Monitoring from './containers/Monitoring.js';
import { AuthLoginModal } from './auth.js';
import { AuthStoreProvider, AuthStore } from './store.js';
import { TopNav } from './auth.js';

const Container = (props) => {
    const [logged, setLogged] = useState(false);
    const loggedUser = AuthStore.user('google');

    useEffect(() => {
        if (typeof window !== 'undefined') window.toast = (a) => toast.success(a);
        if (loggedUser) setLogged(true);
    }, []);

    function renderLogged() {
        if (props.noAuth) {
            return props.section
        } else {
            return logged ? props.section : <span style={{ background: 'gold', float: 'right', marginRight: '70px', padding: '10px' }}>Please log in to continue <i className="bi bi-arrow-up-right" /></span>
        }
    }

    return <div>
        <ToastContainer />
        <TopNav onChange={() => setLogged(true)} />
        {renderLogged()}
    </div>
}

const Wrapper = (props) => <AuthStoreProvider><Container {...props}></Container></AuthStoreProvider>

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <GoogleOAuthProvider clientId={C.clientId}>
        <Router>
            <Routes>
                <Route
                    path="/"
                    element={<Wrapper section={<Main />} />}
                ></Route>
                <Route
                    path="/demandLogin"
                    element={<Wrapper section={<AuthLoginModal target='demand' style='rtb' open={true} />} />}
                ></Route>
                <Route
                    path="/demand"
                    element={<Wrapper section={<DemandPages />} />}
                ></Route>
                <Route
                    path="/monitoring"
                    element={<Wrapper noAuth={true} section={<Monitoring />} />}
                ></Route>
                <Route
                    path="/Expense"
                    element={<Wrapper section={<Expenses />} />}
                ></Route>
                <Route
                    path="/Portfolio"
                    element={<Wrapper section={<Portfolio />} />}
                ></Route>
                <Route
                    path="/Signature"
                    element={<Wrapper section={<Signature />} />}
                ></Route>
            </Routes>
        </Router >
    </GoogleOAuthProvider>
);