import { createStore, useStore } from "zustand";
import { createContext, useState, useContext } from "react";
import { persist, createJSONStorage } from 'zustand/middleware'
import C from './config.js';
import { post } from "./fetch.js";

const api = C.projects.core.api
const AuthStoreContext = createContext(null);

const authStore = (set, get) => ({
    user: {},
    googleLogin: async (googleCred) => {
        if (googleCred) {
            const { credential } = googleCred;
            const isValid = await fetch(`${api}/auth/createGoogleToken`, {
                headers: {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                    'Authorization': credential
                },
                method: 'GET'
            });
            const isvalidJson = await isValid.json();
            set((state) => ({
                user: { ...state.user, ...{ google: isvalidJson } },
            }));
        } else {
            set((state) => ({
                user: { ...state.user, ...{ google: null } },
            }));
        }
    },
    demandLogin: async (demandCred) => {
        if (demandCred) {
            const { email, password } = demandCred;
            const jsonResponse = await post('auth/demandLogin', { email, password })
            jsonResponse.email = email;
            jsonResponse.expires = jsonResponse.end;
            if (jsonResponse.token) {
                set((state) => ({
                    user: { ...state.user, ...{ demand: jsonResponse } },
                }));
            } else {
                set((state) => ({
                    user: { ...state.user, ...{ demand: null } },
                }));
            }
        }
    },
    customLogout: (custom) => {
        set((state) => ({
            user: { ...state.user, ...{ [custom]: null } },
        }));
    },
    logout: () => {
        set(() => ({
            user: null,
        }));
    },
});

const persistedStore = persist(authStore, {
    name: 'auth-storage', // name of the item in the storage (must be unique)
    storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
})

// react context provider
export const AuthStoreProvider = ({ children }) => {
    const [store] = useState(() => createStore(persistedStore))
    return (
        <AuthStoreContext.Provider value={store}>
            {children}
        </AuthStoreContext.Provider>
    )
}

// component React Hook
export const useAuthStore = (selector) => {
    const store = useContext(AuthStoreContext)
    if (!store) {
        throw new Error('Missing AuthStoreContext')
    }
    return useStore(store, selector)
}

// store interface
export const AuthStore = {
    user: (type) => {
        const usr = useAuthStore((state) => state.user);
        if (!type) return usr;
        if (!usr || (type && !usr[type])) return null;

        function verifyExpires(value) {
            let expDate = usr[value].expires;
            if (expDate.toString().length < 13) expDate = expDate * 1000;
            if (new Date().getTime() > expDate) usr[value] = null;
        }

        function returnType(typeGiven) {
            if (typeGiven && usr[typeGiven]) return usr[typeGiven]
        }

        const keysToCheck = Object.keys(usr);
        keysToCheck.forEach(k => {
            if (usr[k] && !usr[k].expires) usr[k] = null;
            if (usr[k] && usr[k].expires) verifyExpires(k);
        })

        return (type) ? returnType(type) : usr;
    }
}

export default useAuthStore;