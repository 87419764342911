import React from 'react';
import Select from 'react-select';

export const Checkbox = ({ label, value, onChange, disabled, array, sublabel, checked }) => {
    function isChecked() {
        let res = (array) ? array.includes(value) : value
        if (checked) res = checked(value);
        return res;
    }
    return (
        <label style={{ margin: '0 1%' }}>
            <input className="form-check-input" type="checkbox" value={value} checked={onChange ? isChecked() : undefined} defaultChecked={onChange ? undefined : isChecked()} onChange={disabled ? null : onChange} disabled={disabled} readOnly={disabled} />
            {label ? ' ' + label : null}{sublabel ? <p className='sublabel'>{'[' + sublabel + ']'}</p> : ''}
        </label>
    );
};


export const CheckboxSimple = ({ label, value, onChange, disabled, array }) => {
    const arrCheck = (array) ? array.includes(value) : value;
    if (label) {
        return (
            <label style={{ margin: '0 1%' }}>
                <input className="form-check-input" type="checkbox" value={value} checked={array ? arrCheck : undefined} onChange={onChange} disabled={disabled} />
                {' ' + label}
            </label>
        );
    } else {
        return (
            <input className="form-check-input" type="checkbox" value={value} checked={array ? arrCheck : undefined} onChange={onChange} disabled={disabled} />
        );
    }
};

export const Toggle = ({ title, checked, onChange }) => {
    return (
        <div className="form-check form-switch" style={{ float: 'right' }}>
            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={checked} onChange={onChange} />
            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{title}</label>
        </div>
    )
}

export function baseSelect({ title, defaultValue, options, onChange, isMulti, marginStyle = '1% 0', onReset, extendedMode }) {
    let optionList = [];
    let initialValue;
    if (options && options.length && !options[0].value) {
        options.forEach(element => {
            optionList.push({ label: element, value: element })
        });
        initialValue = optionList[defaultValue];
    } else {
        optionList = options
        initialValue = defaultValue
    }

    if (extendedMode) {
        const selected= [...optionList];
        function AddSelector(index) {
            selected[index].checked = true
        }

        return (
            <div className="col">
                <h5>{title}</h5>
                {optionList && optionList.map((dim, index) => (
                    <div className="form-check" key={dim.label}>
                        <Checkbox
                            label={dim.label}
                            // value={dim.valu}
                            // array={selectedDim}
                            onChange={() => AddSelector(index)}
                            // disabled={dim === 'appVersionId'}
                        />
                    </div>
                ))}
            </div>
        )
    } else {
        return (
            <>
                {title || null}
                {onReset ?
                    <button style={{ float: 'right' }} type="button" className="btn btn-link" onClick={onReset}>Reset</button>
                    : null}
                <Select
                    defaultValue={initialValue}
                    name="colors"
                    isMulti={isMulti}
                    options={optionList}
                    styles={{
                        container: (base) => ({
                            ...base,
                            width: '100%',
                            margin: marginStyle
                        }),
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={e => onChange(e)}
                />
            </>
        )
    }
}

export function queryStringToObject(url) {
    const newParams = url.split('&');
    const newParamString = newParams.join("\n&");
    return (<div className="row" style={{ paddingTop: '1%' }}>
        <i className="bi bi-clipboard-check" style={{ textAlign: 'right', cursor: 'pointer' }} title='copy to clipboard' onClick={() => { navigator.clipboard.writeText(url) }} />
        <pre>{newParamString}</pre>
    </div>)
}

export function FilterWrapper(filterArr, onFiltered) {
    let filter = {};

    function setNewfilter(e) {
        filter.value = e.target.value
        onFiltered(filter)
    }

    async function afterKeys(val) {
        filter.key = val
    }

    // create selectr key value
    const values = [];
    filterArr.forEach(a => values.push({ label: a, value: a }))
    return (
        <div style={{ display: 'inline-flex' }}>
            <div className="input-group">
                <div className="input-group-btn bs-dropdown-to-select-group">
                    {baseSelect({
                        defaultValue: null,
                        width: '50%',
                        options: values,
                        onChange: e => afterKeys(e.value),
                        marginStyle: '0',
                    })}

                </div>
                <input type="text" style={{ height: 'fit-content' }} className="form-control" name="text" onBlur={e => setNewfilter(e)} />
            </div>
        </div>
    )
}

export const isNumeric = (num) => (typeof (num) === 'number' || typeof (num) === "string" && num.trim() !== '') && !isNaN(num);