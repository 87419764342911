import React from 'react';
import { ColorRing } from "react-loader-spinner";

const style = {
    background: 'none',
    margin: 'auto',
    width: '100%',
    textAlign: 'center',
    padding: '10px'
}

const Loader = ({ size = "80" }) => {
    return (
        <div className="loader-container" style={style}>
            <ColorRing
                visible={true}
                width={size}
                height={size}
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
            />
        </div>
    )
}

export default Loader