import React, { useEffect, useState } from 'react';
import Login from "./containers/Login.js";
import { GoogleLogin } from '@react-oauth/google';
import useAuthStore, { AuthStore } from './store.js';
import { useNavigate } from "react-router-dom";
import ModalWrapper from './containers/ModalWrapper.js';
import C from './config.js';
import Loader from './containers/Loader.js'

export const TopNav = (props) => {
    const [loading, setLoading] = useState(false)
    // store
    const customLogout = useAuthStore((state) => state.customLogout);
    const logout = useAuthStore((state) => state.logout);
    const addGoogleLogin = useAuthStore((state) => state.googleLogin);
    const user = AuthStore.user()
    const navigate = useNavigate();

    async function onLogin(cred) {
        setLoading(true)
        await addGoogleLogin(cred);
        props.onChange(true);
        setLoading(false)
    }

    function onLogout(custom) {
        customLogout(custom);
        props.onChange(false);
    }

    function getUser() {
        const res = { ...user };
        if (res?.dashboard?.dashboardSettings) {
            delete res.dashboard.dashboardSettings;
        }
        return res;
    }

    function UserDropdown() {
        const [dropdown, setDropdown] = useState(false);
        return (
            <div className="dropdown">
                <button className="btn btn-light dropdown-toggle" type="button" onClick={() => setDropdown(!dropdown)}>
                    {user?.google.name ? <>{user.google.name} <img style={{ marginLeft: '10px', width: '1.5rem', borderRadius: '2rem' }} src={user?.google.picture} /> </> : null}
                </button>
                <ul className="dropdown-menu" style={{ display: dropdown ? 'block' : 'none' }}>
                    <li><a className="dropdown-item" href="#" style={{ color: !user.google ? 'none' : 'red' }} onClick={() => onLogout('google')}>Google Logout</a></li>
                    <li><a className="dropdown-item" href="#" style={{ color: !user.demand ? 'none' : 'red' }} onClick={() => !user.demand ? navigate('/demandLogin') : onLogout('demand')}>{!user.demand ? 'Rtb/teqBlaze Login' : 'Rtb/teqBlaze Logout'}</a></li>
                    {user ? <li><a className="dropdown-item" href="#" onClick={() => logout()}>Logout</a></li> : null}
                    {user ? <ModalWrapper title="User" buttonTitle='User Data' style='link'>
                        <div>
                            <pre style={{ textAlign: 'justify' }}>
                                User data:
                                {JSON.stringify(getUser(), null, 4)}
                            </pre>
                        </div>
                    </ModalWrapper> : null}
                </ul>
            </div>
        )
    }

    if (loading) return <Loader/>

    return (
        <nav className="navbar bg-body-tertiary" style={{ padding: 0 }}>
            <div className="container-fluid">
                <span className="navbar-brand mb-0 h1">
                    <a href="/" style={{ marginRight: '1%' }}>
                        <img src={`${C.projects.gravite.logo}`} alt="gravite logo" width="120" height="31" />
                    </a>
                    <h6>Core Admin Tool (CAT)</h6>
                </span>
                <div style={{ float: 'right', display: 'inline-flex' }}>
                    {(!user || !user.google) ? <GoogleLogin auto_select onSuccess={cred => onLogin(cred)} type={user?.given_name ? `icon` : null} onError={(err) => console.log('Login Failed:', err)} /> : <UserDropdown />}
                </div>
            </div>
        </nav>
    );
}

export const AuthChecker = (type, onSync) => {
    if (type == 'supply') {
        const status = isSupplyConnected();
        if (!status) {
            return (<div className="alert alert-info" role="alert">
                You should login on Supply Pages in order to use Gravite API
                <AuthLoginModal target='dashboard' open={false} />
            </div>)
        }
    }
    if (type == 'both') {
        const supplyStatus = isSupplyConnected();
        const demandStatus = isDemandConnected();
        if (!supplyStatus || !demandStatus) {
            return (<div className="alert alert-info" role="alert">
                You should login on Supply & Demand Pages in order to use Gravite API
                {!demandStatus && <AuthLoginModal target='demand' style='rtb' open={false} />}
            </div>)
        } else {
            if (onSync) onSync()
        }
    }
}

export const isSupplyConnected = () => {
    return true
}

export const isDemandConnected = () => {
    const user = AuthStore.user();
    return !!user.demand?.token;
}

export const AuthLoginModal = (props) => {
    const [logged, setLogged] = useState(null);
    const [loading, setLoading] = useState(false)
    const demandLogin = useAuthStore((state) => state.demandLogin);
    const customLogout = useAuthStore((state) => state.customLogout);
    const user = AuthStore.user();
    const navigate = useNavigate();

    async function onLogin(cred) {
        setLoading(true)
        switch (props.target) {
            case 'demand':
                await demandLogin(cred);
                if (props.onChange) props.onChange(user.demand);
                setLogged(user[props.target]);
                break;
        }
        setLoading(false)
    }

    useEffect(() => {
        if (user[props.target]) {
            setLogged(user[props.target]);
        }
    })

    if (loading) return <Loader />

    if (logged && props.fallback) {
        return props.fallback(user[props.target], customLogout)
    }

    if (logged && !props.fallback) {
        return navigate('/')
    }

    return <Login open={props.open || false} text={`${props.target} Login`} style={props.style} onLogin={(creds) => onLogin(creds)} />
}